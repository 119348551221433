import React from 'react';
//import { Helmet } from "react-helmet"
import Layout from '../layout';
import { graphql } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';

if (typeof window !== `undefined`) {
  require('bootstrap/dist/css/bootstrap.min.css');
}

export default function ThankYou(props) {
  return (
    <Layout>
      <div className="container site-content">
        <div class="row mt-4">
          <div class="col-sm-8">
            <p class="">Thank you for contacting us for an estimate.</p>

            <p>
              Sean Burke, who handles all new inquiries, will get back to you
              shortly.
            </p>

            <p>We look forward to working with you!</p>

            <div class="spacer"></div>

            <p>
              Sincerely, <br />
              The Custom Prop Shop Team
            </p>
          </div>

          <div class="col-sm-4">
            <h5 class="side-testimonial text-justify">
              "We love this piece! We used it again this year. Thanks!"
            </h5>
            <h5 class="side-testimonial text-right pb-2">
              <i>Heather V., Pac-12</i>
            </h5>
            <GatsbyImage
              image={props.data.giantTrophy.childImageSharp.gatsbyImageData}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query ThankYouQuery {
    giantTrophy: file(relativePath: { eq: "giant-trophy.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1000, placeholder: BLURRED)
      }
    }
  }
`;
